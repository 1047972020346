import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDay } from 'date-fns';

import axios from 'axios';
import { useQuery } from 'react-query';
import {
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ProgressBar,
} from '@tremor/react';
import { Select } from '../../ui/form/Select';
import { Spinner } from '../../ui/Spinner';
import { UserPreview } from './components/UserPreview';
import { UserType } from '../../types';
import { Position, Tooltip } from 'evergreen-ui';
import { RiCheckboxCircleFill } from '@remixicon/react';
import { useUser } from '@clerk/clerk-react';

type Person = {
  id: string;
  name: string;
};

type Team = {
  id: string;
  name: string;
};

type Office = {
  id: string;
  name: string;
};

const { REACT_APP_API_HOST } = process.env;
const API_HOST = process.env.REACT_APP_API_HOST;

const Overview = () => {
  const [token, setToken] = useState<string>();
  const [currentOffice, setCurrentOffice] = useState<string>('0');
  const [currentTeam, setCurrentTeam] = useState<string>('0');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {}, [token]);

  const { data: response = [], refetch } = useQuery({
    queryKey: ['home-section', { office: currentOffice, team: currentTeam }],
    queryFn: async ({ queryKey }) => {
      setIsLoading(true);
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/people/overview`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          office: currentOffice,
          team: currentTeam,
        },
      });
      setIsLoading(false);
      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  const { data: offices = [] } = useQuery({
    queryKey: ['offices'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/office`,
      });
      return data;
    },
  });

  const { data: teams = [] } = useQuery({
    queryKey: ['teams'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/team`,
      });
      return data;
    },
  });

  useEffect(() => {
    if (currentOffice !== '0' || currentTeam !== '0') {
      refetch();
    }
  }, [currentOffice, currentTeam, refetch]);

  const renderStatus = () => {
    const showStatusMessage = (status: string) => {
      switch (status) {
        case 'In office':
          return 'working in the office';
        case 'Working remotely':
          return 'working remotely';
        case 'Not contracted to work':
          return 'not contracted to work today';
        case 'Non-contracted day':
          return 'not contracted day today';
        case 'Holiday':
          return 'on holiday';
        case 'Sick leave':
          return 'sick';
        case 'Parental leave':
          return 'on parental leave';
        case 'Other leave':
          return 'on other leave';
      }
    };

    function isWeekday(date: Date): boolean {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    }

    const statusUsers =
      response?.status?.flatMap(({ users }: any) => users) || [];

    const remoteUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Working remotely',
    );
    const contractedToWorkUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Not contracted to work',
    );
    const contractedDaykUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Non-contracted day',
    );
    const holidayUsers = statusUsers.filter((user: any) =>
      user.status_now.includes('Holiday'),
    );
    const sickLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Sick leave',
    );
    const parentalLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Parental leave',
    );
    const otherLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Other leave',
    );

    const summary = [
      { label: 'working remotely', count: remoteUsers.length },
      { label: 'on holiday', count: holidayUsers.length },
      { label: 'on sick leave', count: sickLeaveUsers.length },
      { label: 'on parental leave', count: parentalLeaveUsers.length },
      { label: 'on other leave', count: otherLeaveUsers.length },
      { label: 'not contracted to work', count: contractedToWorkUsers.length },
      { label: 'not contracted day today', count: contractedDaykUsers.length },
    ];

    const summaryMessage = summary
      .filter((item) => item.count > 0)
      .map((item) => `${item.count} ${item.label}`)
      .reduce((acc, current, index, array) => {
        return acc + current + (index < array.length - 1 ? ', ' : '');
      }, '');

    const office_users = response?.office?.map(
      ({ office_name, users }: any, index: number) => {
        return (
          <div key={index} className="space-y-2">
            <div>
              <span className="text-gray-400">{users.length} people </span>
              <span>in the {office_name} office:</span>
            </div>
            <div className="-mx-1 flex flex-wrap">
              {users.map((user: UserType) => {
                if (
                  user.status_now === 'In office' ||
                  user.office_name === 'Elstree'
                ) {
                  return (
                    <Tooltip
                      key={user.id}
                      content={user.full_name}
                      position={Position.TOP}
                    >
                      <a href={`/profile/${user.id}`} className="p-1">
                        <img
                          alt={user.full_name}
                          src={user.safe_profile_picture_url}
                          className="h-11 w-11 flex-none rounded-full bg-gray-100"
                        />
                      </a>
                    </Tooltip>
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
      },
    );

    const status_users = response?.status
      ?.filter(({ status_now }: any) => status_now !== 'In office')
      .map(({ status_now, users }: any, index: number) => {
        return (
          <div key={index} className="space-y-2">
            <div>
              <span className="text-gray-400">{users.length} people </span>
              <span>: {status_now}</span>
            </div>
            <div className="-mx-1 flex flex-wrap">
              {users &&
                users.map((user: UserType) => {
                  if (
                    user?.status_now !== 'In office' &&
                    user?.status_now !== 'Not contracted to work'
                  ) {
                    return <UserPreview user={user} key={user.id} />;
                  }
                  return null;
                })}
            </div>
          </div>
        );
      });

    const renderUsers = () => {
      let users: any[] = [];

      if (isWeekday(new Date())) {
        users = users.concat(office_users);
      }

      users = users.concat(status_users);
      return users.map((user) => user);
    };

    return (
      <div>
        <div className="flex flex-wrap items-center justify-between border-b border-gray-200 px-6 py-4 sm:flex-nowrap">
          <div className="">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Team Status today
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              A list of all employees and their locations.
            </p>
          </div>

          <div className="flex flex-row items-center gap-2">
            <div>Show:</div>
            <Select onChange={(event) => setCurrentTeam(event.target.value)}>
              <option value={0}>Everyone</option>
              {teams.data &&
                teams.data.map((team: Team) => {
                  return (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  );
                })}
            </Select>
            <div>from</div>
            <Select onChange={(event) => setCurrentOffice(event.target.value)}>
              <option value={0}>All locations</option>
              {offices.data &&
                offices.data.map((office: Office) => {
                  return (
                    <option key={office.id} value={office.id}>
                      {office.name}
                    </option>
                  );
                })}
            </Select>
          </div>
        </div>

        <>
          {isLoading ? (
            <Spinner className="my-8" />
          ) : (
            <>
              <div className="space-y-6 px-6 py-4">{renderUsers()}</div>

              <div className="px-6 py-4">
                {summaryMessage.length > 0 ? (
                  <div className="text-md mt-1 flex flex-row gap-2 text-gray-500">
                    {summaryMessage}
                  </div>
                ) : response?.office?.length !== 0 ? (
                  <div className="text-md mt-1 text-gray-500">
                    No one is recorded as working remotely, on holiday, on
                    parental leave, on sick leave, or away for other reasons
                  </div>
                ) : (
                  <div className="text-md mt-1 text-gray-500">
                    No users found with current filters.
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </div>
    );
  };

  return <>{renderStatus()}</>;
};

const Onboarding = () => {
  const steps = [
    {
      id: '1.',
      title: 'Set up your organization',
      description:
        'You successfully created your account. You can edit your account details anytime, such as company name, industry, and size.',
      status: 'complete',
      href: '#',
    },
    {
      id: '2.',
      title: 'Import or connect employee data',
      description:
        'Sync your existing employee data from your HR system or import employees manually or through a CSV file.',
      status: 'open',
      href: '#',
    },
    {
      id: '3.',
      title: 'Define absence policies',
      description:
        'Configure your company’s absence policies, including PTO, sick leave, and other leave types.',
      status: 'open',
      href: '#',
    },
    {
      id: '4.',
      title: 'Set up approval workflows',
      description:
        'Create approval workflows for managing leave requests, from employee submissions to manager approvals.',
      status: 'open',
      href: '#',
    },
    {
      id: '5.',
      title: 'Assign user roles and permissions',
      description:
        'Define roles for administrators, managers, and employees, with appropriate permissions for absence management.',
      status: 'open',
      href: '#',
    },
    {
      id: '6.',
      title: 'Track absences and generate reports',
      description:
        'Start tracking employee absences and generate detailed reports on absenteeism and leave trends.',
      status: 'open',
      href: '#',
    },
  ];

  return (
    <div className="px-6 py-6">
      <div className="sm:mx-auto sm:max-w-lg">
        <h3 className="dark:text-dark-tremor-content-strong text-tremor-title font-semibold text-tremor-content-strong">
          Getting started
        </h3>
        <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
          Follow the steps to set up your workspace. This allows you to use your
          account with all relevant policies.
        </p>
        <div className="mt-4 flex items-center justify-end space-x-4">
          <span className="dark:text-dark-tremor-content text-red-500 text-tremor-content">
            Step 1/{steps.length}
          </span>
          <ProgressBar value={25} showAnimation={false} className="w-32" />
        </div>
        <ul role="list" className="mt-4 space-y-4">
          {steps.map((step) => (
            <li
              key={step.id}
              className="dark:border-dark-tremor-border dark:bg-dark-tremor-background relative rounded-tremor-default border border-tremor-border bg-tremor-background p-4"
            >
              <div className="flex items-start space-x-3">
                {step.status === 'complete' ? (
                  <RiCheckboxCircleFill
                    className="size-6 dark:text-dark-tremor-brand shrink-0 text-red-500"
                    aria-hidden={true}
                  />
                ) : (
                  <span
                    className="size-6 dark:text-dark-tremor-content flex items-center justify-center font-medium text-tremor-content"
                    aria-hidden={true}
                  >
                    {step.id}
                  </span>
                )}
                <div>
                  <h3 className="dark:text-dark-tremor-content-strong font-medium text-tremor-content-strong">
                    <a href={step.href} className="focus:outline-none">
                      {/* extend link to entire card */}
                      <span className="absolute inset-0" aria-hidden={true} />
                      {step.title}
                    </a>
                  </h3>
                  <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
                    {step.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="dark:bg-dark-tremor-background-muted mt-6 rounded-tremor-default bg-tremor-background-muted p-4">
          <h4 className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong">
            Need help?
          </h4>
          <p className="dark:text-dark-tremor-content mt-1 text-tremor-default text-tremor-content">
            Connect with a member of our team at{' '}
            <a
              href="mailto:support@redstonehr.com"
              className="dark:text-dark-tremor-brand font-medium text-red-500"
            >
              support@redstonehr.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export function HomeRoute() {
  const { data: response, status } = useQuery({
    queryKey: ['onboarding-status'],
    queryFn: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/onboarding`,
      });

      return data;
    },
  });

  useEffect(() => {
    if (status === 'success' && response?.data.status === 'onboarding') {
    }
  }, [response?.data.status, status]);

  return (
    <>
      <Greeting />
      <div className="rounded-lg-white overflow-hidden bg-white shadow">
        {response?.data?.status !== 'onboarding' && (
          <TabGroup>
            <TabList className="ml-3 mt-1">
              <Tab icon={UserGroupIcon}>Overview</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Overview />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        )}
        {response?.data?.status === 'onboarding' && <Onboarding />}
      </div>
    </>
  );
}

function Greeting() {
  const { user } = useUser();

  const { data: response } = useQuery({
    queryKey: ['identity-state'],
    queryFn: async (options) => {
      const { data } = await axios.get(`${API_HOST}/v1/identity/state`);
      return data;
    },
  });

  return (
    <div className="mb-4">
      <h1 className="mb-3 text-2xl font-semibold text-gray-900">
        {moment().format('MMMM Do YYYY')}
      </h1>
      <h3 className="text-1xl mb-1 text-gray-900">Hello {user?.fullName}</h3>
      <h3 className="text-1xl text-gray-900">{user?.firstName}</h3>
      <h3 className="text-1xl text-gray-900">{response?.state?.status}</h3>
    </div>
  );
}
