import { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { getDay } from 'date-fns';
import timeaway from '../../../network/timeaway';
import classNames from 'classnames';
import Notification from '../../../ui/component/notification';
import { isError } from 'lodash';

enum ETimeawayType {
  HolidayPaid = 'Holiday (Paid)',
  HolidayUnpaid = 'Holiday (Unpaid)',
  WorkingRemotely = 'Working remotely',
  SickLeave = 'Sick leave',
  ParentalLeave = 'Parental leave',
  OtherLeave = 'Other leave',
}

enum ETimeofError {
  OVERLAPPING_REQUESTS = 'OVERLAPPING_REQUESTS',
}

function isWeekday(date: Date): boolean {
  const day = getDay(date);
  return day !== 0 && day !== 6;
}

export default () => {
  const minDate = useMemo(() => new Date().toISOString().split('T')[0], []);
  const [validationError, triggerValidationError] = useState(false);
  const [showNotification, setNotificationVisibility] = useState(false);
  const [showErrorTimeawayNotification, setShowErrorTimeawayNotification] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const buttonClassNames = classNames(
    'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
    {
      'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500':
        !validationError,
      'bg-gray-400 text-gray-700 cursor-not-allowed': validationError,
    },
  );

  const showTimeawNotification = useCallback(() => {
    setNotificationVisibility(true);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          kind: ETimeawayType.HolidayPaid,
          start: new Date(),
          start_time: 'start_of_day',
          end: new Date(),
          end_time: 'midday',
        }}
        onSubmit={async (values) => {
          try {
            await timeaway.bookTime(
              values.kind,
              { date: values.start, time: values.start_time },
              { date: values.end, time: values.end_time },
            );
            setShowErrorTimeawayNotification(false);
            setNotificationVisibility(true);
          } catch (error: any) {
            if (
              error.response.data.status === ETimeofError.OVERLAPPING_REQUESTS
            ) {
              setErrorMessage(
                `This time is already booked. Please choose another time.`,
              );
            }
            setNotificationVisibility(false);
            setShowErrorTimeawayNotification(true);
          }
        }}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Time away type
                    </label>
                    <Field
                      as="select"
                      id="kind"
                      name="kind"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    >
                      <option value={ETimeawayType.HolidayPaid}>
                        Holiday (Paid)
                      </option>
                      <option value={ETimeawayType.HolidayUnpaid}>
                        Holiday (Unpaid)
                      </option>
                      <option value={ETimeawayType.WorkingRemotely}>
                        Working remotely
                      </option>
                      <option value={ETimeawayType.SickLeave}>
                        Sick leave
                      </option>
                      <option value={ETimeawayType.ParentalLeave}>
                        Parental leave
                      </option>
                      <option value={ETimeawayType.OtherLeave}>
                        Other leave
                      </option>
                    </Field>
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Start date
                    </label>
                    <Field
                      type="date"
                      name="start"
                      id="start-date"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                      min={minDate}
                    >
                      {(props: any) => {
                        const { field } = props;
                        const selectedDate = new Date(field.value);
                        const day = getDay(selectedDate);
                        const isDisabled = !isWeekday(selectedDate);

                        // useEffect(() => {
                        //   if (typeof field.value !== "undefined") {
                        //     triggerValidationError(isDisabled);
                        //   }
                        // }, [field.value]);

                        return (
                          <div>
                            <input
                              {...field}
                              type="date"
                              min={minDate}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                            />
                            {isDisabled && (
                              <div className="mt-1 text-sm text-red-500">
                                {day === 0 ? 'Sunday' : 'Saturday'} is not a
                                valid day to book timeaway.
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Start time
                    </label>
                    <Field
                      as="select"
                      id="start-time"
                      name="start_time"
                      autoComplete="start-date"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    >
                      <option value="start_of_day">the start of the day</option>
                      <option value="midday">midday</option>
                    </Field>
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      End date
                    </label>
                    <Field
                      type="date"
                      id="end-date"
                      name="end"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                      min={values.start}
                    >
                      {(props: any) => {
                        const { field } = props;
                        const { value } = field;
                        const selectedDate = new Date(value);
                        const day = getDay(selectedDate);
                        const isDisabled = !isWeekday(selectedDate);
                        triggerValidationError(isDisabled);

                        return (
                          <div>
                            <input
                              {...field}
                              type="date"
                              min={minDate}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                            />
                            {isDisabled && (
                              <div className="mt-1 text-sm text-red-500">
                                {day === 0 ? 'Sunday' : 'Saturday'} is not a
                                valid day to book timeaway.
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      End time
                    </label>
                    <Field
                      as="select"
                      id="end-date"
                      name="end_time"
                      autoComplete="start-date"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    >
                      <option value="midday">midday</option>
                      <option value="end_of_day">the end of the day</option>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className={buttonClassNames}
                  disabled={validationError}
                >
                  Send request
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Notification
        title="Time off requested."
        message="Your request for time off has been successfully submitted."
        open={showNotification}
        close={() => setNotificationVisibility(false)}
      />
      <Notification
        title="Time off can not be requested."
        message={errorMessage}
        open={showErrorTimeawayNotification}
        close={() => setShowErrorTimeawayNotification(false)}
        isError={true}
      />
    </>
  );
};
