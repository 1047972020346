import axios from 'axios';
import { useQuery } from "react-query"

const { REACT_APP_API_HOST } = process.env;

export function useProfileDetails() {
  const { data } = useQuery({
    queryKey: ['home-section'],
    queryFn: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/profile`,
      });
      return data;
    },
  });
  return data;
}

export const useQueryHolidayRequest = async (
  page: number,
  count: number,
) => {
    const { data } = await axios({
      method: 'get',
      url: `${REACT_APP_API_HOST}/v1/timeaway/requests`,
      params: { page, count },
    });
    return data;
};