import { useState, useEffect, useReducer } from 'react';
import { QueryClient } from 'react-query';
import { useClerk, useUser } from '@clerk/clerk-react';
import { GlobalReducer } from '../reducer/GlobalReducer';
import axios from 'axios';

export function useAppInitialization() {
  const [loaderActive, setLoaderActive] = useState(false);
  const [token, setToken] = useState<string>('');
  const queryClient = new QueryClient();

  const { isSignedIn, isLoaded } = useUser();
  const { session, redirectToSignIn } = useClerk();

  const [state, dispatch] = useReducer(GlobalReducer, {
    notification: {
      auth: {
        login: false,
        reset: false,
      },
    },
  });

  // Set up Axios interceptors
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(async (config) => {
      const token = await session?.getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    });

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [session]);

  // Handle authentication and loading states
  useEffect(() => {
    async function checkUser() {
      if (!isLoaded) {
        setLoaderActive(true);
      } else if (!isSignedIn) {
        await redirectToSignIn();
        setLoaderActive(true);
      } else {
        setLoaderActive(false);
      }
    }

    checkUser();
  }, [isLoaded, isSignedIn, redirectToSignIn]);

  // Get the access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await session?.getToken();
      if (token) {
        setToken(token);
      }
    };

    if (isSignedIn) {
      getAccessToken();
    }
  }, [session, isSignedIn]);

  return { loaderActive, queryClient, state, dispatch, token };
}

