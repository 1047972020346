import { TextInput, Select, SelectItem } from '@tremor/react';
import { RiAddLine, RiDeleteBinLine, RiPencilFill } from '@remixicon/react';

type Office = {
  id: number;
  name: string;
};

type Role = {
  id: number;
  name: string;
  officeId: number;
};

interface IRoleManagement {
  roles: Role[];
  offices: Office[];
  newRole: string;
  setNewRole: (value: string) => void;
  addRole: () => void;
  deleteRole: (id: number) => void;
  selectedOffice: string;
  setSelectedOffice: (value: string) => void;
}

export default function RoleManagement({
  roles,
  newRole,
  offices,
  addRole,
  deleteRole,
  setNewRole,
  selectedOffice,
  setSelectedOffice,
}: IRoleManagement) {
  return (
    <div className="container mx-auto space-y-5 py-8 ">
      <h1 className="dark:text-dark-tremor-content-strong text-2xl font-semibold text-tremor-content-strong">
        Company Structure Management
      </h1>
      <div className="space-y-4">
        <h2 className="text-xl font-semibold ">Roles</h2>
        <div className="flex items-stretch space-x-2">
          <TextInput
            type="text"
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            placeholder="New role name"
          />
          <Select
            className="w-1/6 self-center"
            name="officeSelect"
            value={selectedOffice}
            onValueChange={setSelectedOffice}
          >
            {offices.map((office) => (
              <SelectItem value={office.id.toString()}>
                {office.name}
              </SelectItem>
            ))}
          </Select>
          <button
            className=" flex w-1/6 items-center justify-around rounded-lg bg-black py-2 text-lg text-white"
            onClick={addRole}
          >
            <RiAddLine className="size-6 " />
            Add Role
          </button>
        </div>
        <ul className="space-y-3">
          {roles.map((role) => (
            <li
              key={role.id}
              className="flex items-center justify-between rounded-lg border px-4 py-2"
            >
              <span>{role.name}</span>
              <div>
                <button className="p-3">
                  <RiPencilFill className="size-5" />
                </button>
                <button className="p-3" onClick={() => deleteRole(role.id)}>
                  <RiDeleteBinLine className="size-5" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
