import axios from 'axios';
import { KindeClient } from '@kinde-oss/kinde-auth-pkce-js';
import { KindeAuthManager } from '../app/auth/kinde';

const { REACT_APP_API_HOST } = process.env;

const API_HOST = process.env.REACT_APP_API_HOST;

export enum FilterType {
  Office = 'FILTER_OFFICE',
  Daily = 'FILTER_DAILY',
}

export async function patchUserWorkspaceSettings(user: any) {
  const { data } = await axios.patch(`${API_HOST}/v1/workspace`, {
    user,
  });
  return data;
}

export async function scheduleInterview(interview: any) {
  const { data } = await axios.post(`${API_HOST}/v1/interview`, { interview });
  return data;
}

export async function patchUserPrivacySettings(user: any) {
  const { data } = await axios.patch(`${API_HOST}/v1/privacy`, {
    user,
  });
  return data;
}

export async function createJob(job: any) {
  const { data } = await axios.post(`${API_HOST}/v1/ats/vacancy`, { job });
  return data;
}

export async function offerVacancy(offer: any) {
  const { data } = await axios.post(`${API_HOST}/v1/ats/vacancy/offer`, { offer });
  return data;
}

export async function createUser(user: any) {
  const { data } = await axios.post(`${API_HOST}/v1/people`, { user });
  return data;
}

export async function editWorkoff(workoff: any) {
  const { data } = await axios.patch(`${API_HOST}/v1/workoff`, { workoff });
  return data;
}

export async function addKpi(kpi: any) {
  const { data } = await axios.post(`${API_HOST}/v1/report`, { kpi });
  return data;
}

export async function updateUserLogo(logo: any) {
  const { data } = await axios.patch(`${API_HOST}/v1/workspace/logo`, logo, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function deleteUserLogo() {
  const { data } = await axios.delete(`${API_HOST}/v1/workspace/logo`);
  return data;
}

export async function createTeam(team: any) {
  const { data } = await axios.post(`${API_HOST}/v1/team`, { team });
  return data;
}

export async function editUser(user: any) {
  const { data } = await axios.patch(`${API_HOST}/v1/people/${user.id}`);
  return data;
}

export class NetworkRequestManager {
  private manager: KindeAuthManager;

  constructor() {
    this.manager = new KindeAuthManager();
  }

  async getCalendarEvents(type: FilterType) {
    const operationName = 'GET_CALENDAR_EVENTS';
  }

  async getOverview() {
    const operationName = 'GET_OVERVIEW';
    const token = await this.manager.getToken();

    const { data = [] } = await axios({
      method: 'get',
      url: `${REACT_APP_API_HOST}/v1/people/overview`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  }

  async createUser(user: any) {
    const operationName = 'CREATE_USER';
    const token = await this.manager.getToken();

    const { data = [] } = await axios({
      method: 'post',
      url: `${REACT_APP_API_HOST}/v1/people/overview`,
      headers: { Authorization: `Bearer ${token}` },
      data: user,
    });

    return data;
  }

  async getPeople() {
    const operationName = 'GET_PEOPLE';
    const token = await this.manager.getToken();

    const { data = [] } = await axios({
      method: 'get',
      url: `${REACT_APP_API_HOST}/v1/people`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  }

  async getHolidayRequests() {
    const operationName = 'GET_HOLIDAY_REQUESTS';
    const token = await this.manager.getToken();

    const { data } = await axios({
      method: 'get',
      url: `${REACT_APP_API_HOST}/v1/timeaway/requests`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  }
}
