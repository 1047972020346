export const renderDecision = (decision: string) => {
  switch (decision) {
    case 'pending':
      return 'Pending';

    case 'approved':
      return 'Approved';

    case 'canceled':
      return 'Canceled';

    default:
      return 'Unknown';
  }
}