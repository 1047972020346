import createKindeClient, { KindeClient } from '@kinde-oss/kinde-auth-pkce-js';

const { KINDE_CLIENT_ID, KINDE_DOMAIN } = process.env;

export class KindeAuthManager {
  private kinde: KindeClient | undefined = undefined;

  constructor() {}

  private async initialiseKinde() {
    const kinde = await createKindeClient({
      client_id: '2726e41e2add49d0b5897e7b35a19096',
      domain: 'https://redstonehr.kinde.com',
      redirect_uri: window.location.origin,
    });

    return kinde;
  }

  private getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length < 3) {
      // No subdomain present
      return null;
    } else {
      // Return the subdomain part
      return parts[0];
    }
  }

  private async fetchOrgId(subdomain: string) {
    return 'org_d4c043342755';
  }

  async getClient() {
    this.kinde = await this.initialiseKinde();
    return this.kinde;
  }

  async login(app_state?: object) {
    const subdomain = this.getSubdomain();
    this.kinde = await this.initialiseKinde();

    if (subdomain !== null) {
      const org = await this.fetchOrgId(subdomain);
      await this.kinde.login({ org_code: org, app_state });
    } else {
      const org = await this.fetchOrgId('');
      await this.kinde.login({ org_code: org, app_state });
    }
  }

  async logout() {
    this.kinde = await this.initialiseKinde();
    await this.kinde.logout();
  }

  async getToken() {
    this.kinde = await this.initialiseKinde();
    return this.kinde.getToken();
  }
}
