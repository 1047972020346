import axios from 'axios';
import clsx, { type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const getOrganisation = async () => {
  const API_HOST = process.env.REACT_APP_API_HOST;

  const { data } = await axios.get(`${API_HOST}/v1/organisation`);
  return data;
};

export const getOnboardingData = async (token: string) => {
  const API_HOST = process.env.REACT_APP_API_HOST;
  const { data } = await axios.get(`${API_HOST}/v1/onboarding`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export function cx(...args: ClassValue[]) {
  return twMerge(clsx(...args));
}
