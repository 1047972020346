import { useState, useCallback, useEffect } from 'react';
import timeaway from '../../network/timeaway';
import BookRecurringTimeAway from './components/BookRecurringTimeAway';
import BookTimeAway from './components/BookTimeAway';

export function TimeAwayRoute() {
  const [type, setType] = useState('');
  const [allocatedTimeaway, setAllocatedTimeaway] = useState<any>({});

  const [startDate, setStartDate] = useState({
    startDate: new Date().toISOString(),
    startTime: '',
  });

  const [endDate, setEndDate] = useState({
    endDate: new Date().toISOString(),
    endTime: '',
  });

  const bookTimeAway = useCallback(async () => {
    await timeaway.bookTime(
      type,
      new Date(startDate.startDate),
      new Date(endDate.endDate),
    );
  }, [endDate.endDate, startDate.startDate, type]);

  useEffect(() => {
    timeaway.getTimeAway().then((response: any) => {
      setAllocatedTimeaway(response?.data);
    }).catch(error => console.log("Error ",error));
  }, []);

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Book time away
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Book time away pending approval
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <BookTimeAway />
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      {/** @TODO: We need to be able to create a hook that can provide the values we need to determine the values for an individuals timeaway */}

      {allocatedTimeaway?.weekly_working_remotely?.allowed?.value > 0 && (
        <div>
          <BookRecurringTimeAway />
          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200">
                <div className="overflow-hidden shadow sm:rounded-md"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                My time away
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                See your current time away
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div className="col-span 12 sm:col-span-12">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  My time away in
                </label>
                <select
                  id="year"
                  name="year"
                  autoComplete="year-name"
                  className="mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                >
                  <option>2024</option>
                </select>
                <div className="mt-8 grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Holiday (paid)
                    </label>
                    <h3 className="mt-2">
                      {allocatedTimeaway?.paid_holiday?.used} /{' '}
                      {allocatedTimeaway?.paid_holiday?.allowed?.days} days
                      remaining
                    </h3>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Holiday (unpaid)
                    </label>
                    <h3 className="mt-2">
                      {allocatedTimeaway?.unpaid_holiday?.used} /{' '}
                      {allocatedTimeaway?.unpaid_holiday?.allowed?.days} days
                      remaining
                    </h3>
                  </div>
                </div>

                <div className="mt-5 grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Remote working per week
                    </label>
                    <h3 className="mt-2">
                      {allocatedTimeaway?.working_remotely?.allowed?.days} days
                    </h3>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Paid sick leave per year
                    </label>
                    <h3 className="mt-2">
                      {allocatedTimeaway?.paid_sick_leave?.allowed?.days} days
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
