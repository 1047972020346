import { TextInput } from '@tremor/react';
import { RiAddLine, RiDeleteBinLine, RiPencilFill } from '@remixicon/react';


type Office = {
  id: number;
  name: string;
}

interface IOfficeManagement{
  offices: Office[];
  newOffice: string;
  setNewOffice: (value: string) => void;
  addOffice: () => void;
  deleteOffice: (id: number) => void;
}

export default function OfficeManagement({offices, newOffice, setNewOffice, addOffice, deleteOffice }: IOfficeManagement) {

  return (
    <div className="container mx-auto space-y-5 py-8 ">
      <h1 className="dark:text-dark-tremor-content-strong text-2xl font-semibold text-tremor-content-strong">
        Company Structure Management
      </h1>
        <div className="space-y-4">
          <h2 className="text-xl font-semibold ">Offices</h2>
          <div className="flex space-x-2">
            <TextInput
              type="text"
              value={newOffice}
              onChange={(e) => setNewOffice(e.target.value)}
              placeholder="New office name"
            />
            <button
              className=" flex w-1/6 items-center justify-around rounded-lg bg-black py-2 text-lg text-white"
              onClick={addOffice}
            >
              <RiAddLine className="size-6 " />
              Add Office
            </button>
          </div>
          <ul className="space-y-3">
            {offices.map((office) => (
              <li key={office.id} className="flex items-center justify-between border px-4 py-2 rounded-lg">
                <span>{office.name}</span>
                <div className="space-x-1">
                  <button className="p-3">
                    <RiPencilFill className="size-5" />
                  </button>
                  <button
                    className="p-3"
                    onClick={() => deleteOffice(office.id)}
                  >
                    <RiDeleteBinLine className="size-5" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
      </div>
    </div>
  );
}
